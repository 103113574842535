<template lang="pug">
page.b2s(:show-header-links='false', :ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')

  //- Header
  base-header(
    slot='header',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )
    .d-flex.align-items-center.justify-content-between.px-3.py-1(slot='branding')
      img(:src='prestonLogoSrc', width='39', height='39', alt='Prestonplayz logo')
      b-nav#mainnav-desktop.align-items-center
          b-nav-item(:href='$surf.links.giveaways', @mousedown='trackEvent("to_browse")', target='_blank')
            .d-inline-flex.align-items-center.fw-heavy
              i.icon.icon-sm.icon-inline.mr-2(:class='$icons.prize')
              | Browse Giveaways

  //- Content
  template(slot='content')

    //- Banner Video

    page-section.b2s-section.b2s-section--video(:fullWidth='true', align='center')
      template(slot='content')
        surf-video(
          :src='videoSrc',
          :auto-play='true',
          :loop='false',
          :cover-mode='true',
          :show-mute-button='false',
          :ga-event-category='gaEventCategory',
          :ga-event-label='gaEventLabel'
        )
        i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpTo("main")')

    //- Main
    page-section#main.b2s-section.b2s-section--background.b2s-section--enter.page-pt-md-alt.page-pb-md(align='center')
      template(slot='content')

        //- Background Video
        video.background-video(
          ref='video',
          :src='bgVideoSrc',
          :loop='true',
          :muted='true',
          :autoplay='true',
        )

        //- Loading
        loading-icon.page-mt-lg-alt.page-mb-xxl.position-relative(v-if='isLoading', size='lg', color='b2s-yellow')

        //- Ended View
        template(v-else-if='isEndedView')
          h1.h2.fw-bold.position-relative Thanks to everyone who entered!
          .fs-md.page-mb-md-alt View winners

          //- Giveaway list
          giveaway-list.position-relative(
            :list='giveawayList',
            :show-prizes='false',
            :show-dates='false',
            :show-state='true',
            :show-action-button='true',
            action-button-variant='b2s-yellow',
            title-size='md-alt',
            title-align='center',
            tile-size='full',
            tile-size-sm='half',
            tile-size-lg='half',
            tile-classes='sm:page-px-sm',
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel'
          )

        //- All other scenarios
        template(v-else)
          .text-center.mx-auto.max-w-md.page-mb-md.position-relative
            h1.h2.fw-bold A new giveaway every Friday in July
            h1.h2.fw-bold Enter below!

          //- Error
          template(v-if='isError') Sorry, there was an error, please try refreshing the page.

          //- No Giveaways
          template(v-else-if='!giveawayList.length') Coming Soon

          //- Has Giveaways
          template(v-else)

            //- Countdown
            component.bg-color-b2s-yellow.px-3.py-1.page-sm.page-md-alt.rounded-sm.position-relative(
              :is='countdownTemplate',
              :giveaway='topGiveaways[0]',
              :label='isUnderway ? "Ends In:" : "Starts In:"',
              label-color='b2s-purple',
              countdown-color='b2s-purple'
            )

            giveaway-list.mt-5.enlarge(
              :list='topGiveaways',
              :show-prizes='false',
              :show-dates='false',
              :show-state='false',
              :show-action-button='true',
              title-classes='fw-bold'
              action-button-variant='b2s-yellow',
              title-size='lg',
              title-align='center',
              tile-size='full',
              tile-size-sm='half',
              tile-size-lg='lg',
              tile-classes='full:page-px-sm',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )

            //- Giveaway list
            giveaway-list.mt-4.position-relative.enlarge--small(
              :list='giveawayList',
              :show-prizes='false',
              :show-dates='true',
              date-format='M/D/YYYY h:mm a'
              :show-state='false',
              :show-action-button='false',
              action-button-variant='b2s-yellow',
              title-align='left',
              tile-size='md',
              tile-size-sm='md',
              tile-size-lg='md',
              title-size='md',
              tile-classes='lg:page-px-xs',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )

  //- Footer
  template(slot='footer')
    base-footer.position-relative(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel', :linkToHome='false')
      template(slot='custom-image')
        .mt-3.mt-sm-0

</template>
<script>
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import GiveawayEndsIn from '../components/GiveawayEndsIn'
import BaseFooter from '../components/BaseFooter'
import multipleGiveawaysInSyncMixin from '../mixins/base/multiple-giveaways-in-sync'
import SurfVideo from '../components/SurfVideo'

export default {
  name: 'Prestonb2s',
  mixins: [multipleGiveawaysInSyncMixin],
  data() {
    return {
      pageMetaTitle: 'Preston B2S',
      idTokens: ['hm5yxqw', 'kdVl_h0', 'IEfLhV_', 'EnkxdnU'],
      videoSrc: require('../assets/themes/prestonb2s/video/PrestonB2S-Giveaway.mp4'),
      bgVideoSrc: require('../assets/themes/prestonb2s/video/Landing-Page-BG_Animation.mp4'),
      topGiveaways: [],
      prestonLogoSrc: require('../assets/themes/prestonb2s/images/preston-logo.png')
    }
  },

  computed: {
    countdownTemplate() {
      return this.isUnderway ? GiveawayEndsIn : GiveawayStartsIn
    }
  },
  methods: {
    setTopGiveaways(giveawayList) {
      this.topGiveaways = giveawayList.filter((giveaway) => {
        return giveaway.isActive
      })
      //if no active giveaways
      if (this.topGiveaways.length == 0) {
        //if no giveaways -> show first giveaway else show last
        this.topGiveaways.push(giveawayList[0].isEnded ? giveawayList[giveawayList.length - 1] : giveawayList[0])
      } else {
        //Only show one giveawy at the top
        this.topGiveaways = [this.topGiveaways[this.topGiveaways.length - 1]]
      }
    },

    // Override from 'multipleGiveawaysInSyncMixin'
    sortGiveaways(giveawayList) {
      const sortedList = giveawayList.sort((a, b) => {
        return a.record.startTime > b.record.startTime ? 1 : -1
      })
      this.setTopGiveaways(sortedList)
      return sortedList
    }
  },
  components: {
    GiveawayStartsIn,
    GiveawayEndsIn,
    BaseFooter,
    SurfVideo
  }
}
</script>
<style lang="scss" src="../assets/themes/prestonb2s/scss/main.scss" />
